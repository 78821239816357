.service-content {
    z-index: 3;
}

.service-block {
    margin-bottom: 32px;
    margin-top: 5px;
    width: 90vw;
    aspect-ratio: 5 / 1;
    border-radius: 16px;
    box-shadow: inset 0 0 20px rgba(0, 0, 0, 1), 0 0 20px #8A2124;
    object-fit: cover;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    z-index: 3;
}

#service-block1 {
    background-image: url("/src/assets/services/Wedding Right Size.jpg");
}

#service-block2 {
    background-image: url("/src/assets/services/Kam fixed bar.jpg");
}

#service-block3 {
    background-image: url("/src/assets/services/Music Videos Right Size.jpg");
}

#service-block4 {
    background-image: url("/src/assets/services/Trailer Right Size.jpg");
}

#service-block5 {
    background-image: url("/src/assets/services/Promo Right Size.jpg");
}

#service-block6 {
    background-image: url("/src/assets/services/Live Event Right Size.jpg");
}

.service-block-text {
    text-align: center;
    font-size: 60px;
    font-weight: bold;
}

.links {
    font-family: "Oswald", sans-serif;
    color: white;
    text-decoration: none;
}

.links:hover {
    text-decoration: underline;
}

/* Media queries */
@media screen and (max-width: 768px) {
    .service-block-text {
    font-size: xx-large;
    }

    .service-block {
        margin-bottom: 32px;
        width: 90vw;
        aspect-ratio: 3 / 1;
        border-radius: 16px;
    }
}