/*
STORY
*/

.blockImageL {
    float: left;
    border-radius: 16px;
    box-shadow: inset 0 0 20px rgba(0, 0, 0, 1), 0 0 40px #8A2124;
    width: 50%;
    margin-right: 3%;
    margin-bottom: 5%;
    height: auto;
}

.blockImageR {
    float: right;
    margin-left: 3%;
    margin-bottom: 5%;
    border-radius: 16px;
    box-shadow: inset 0 0 20px rgba(0, 0, 0, 1), 0 0 40px #8A2124;
    width: 50%;
    height: auto;
}

.blockContent {
    text-align: justify;
    z-index: 3;
    color: white;
    font-size: 2.5vw;
}

.block1 {
    z-index: 3;
    padding: 0% 3%;
}

.fade-in-section {
    opacity: 0;
    z-index: 3;
    transform: translateY(20vh);
    visibility: hidden;
    transition: opacity 0.6s ease-out, transform 1.2s ease-out;
    will-change: opacity, visibility;
}

.fade-in-section.is-visible {
    opacity: 1;
    transform: none;
    visibility: visible;
}


/* Media queries */
@media screen and (max-width: 768px) {

    .block1 {
        display: flex;
        flex-direction: column;
        z-index: 3;
        padding: 1% 3% 10%;
    }

    .blockContent {
        font-size: x-large;
    }

    .blockImageL {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 90%;
        height: auto;
    }

    .blockImageR {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 90%;
        height: auto;
    }
}