* {
  margin: 0;
  padding: 0;
}

@keyframes move-twinkle-back {
  from {
    background-position: 0 0;
  }
  to {
    background-position: -10000px 5000px;
  }
}


.stars,
.twinkling {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: block;
  position: fixed;
}

.stars {
  background: black url(../../assets/stars/stars.png) repeat top center;
  z-index: 0;
}

.twinkling {
  background: transparent url(../../assets/stars/twinkling.png) repeat top center;
  z-index: 1;
  animation: move-twinkle-back 200s linear infinite;
}
