/*
PORTFOLIO
*/

.youtube-title {
    font-size: 4vw;
}

/* Add a red border to .portfolio-block */
.portfolio-block {
    z-index: 3;
    display: flex;
    padding-top: 5%;
    /* Add this line to set a red border */
}

.portfolio-block-center {
    z-index: 3;
    display: flex;
    flex-direction: column;
    padding-bottom: 5%;
}

.youtube-videos-left {
    z-index: 3;
    float: left;
    padding-left: 2%;
    height: 100vh/1.5;
    width: 100vw/1.8;
}

.youtube-videos-right {
    z-index: 3;
    float: right;
    padding-right: 2%;
    height: 100vh/1.5;
    width: 100vw/1.8;
}

.youtube-videos-center {
    z-index: 3;
    float: center;
    padding-right: 2%;
    padding-left: 2%;
     height: 560;
    width: 320;
}

.videoText-center {
    z-index: 3;
    color: white;
    font-size: 2.3vw;
    padding-right: 2%;
    padding-left: 2%;
    margin: 0rem 1rem;
}

.videoText-left {
    z-index: 3;
    color: white;
    font-size: 2.3vw;
    padding-right: 2%;
    margin: 0rem 1rem;
}

.videoText-right {
    z-index: 3;
    color: white;
    font-size: 2.3vw;
    padding-left: 2%;
    margin: 0rem 1rem;
}

/* Buttons */

.slideShowButton {
    appearance: button;
    background-color: #000;
    background-image: none;
    border: 1px solid #000;
    border-radius: 4px;
    box-shadow: #fff 4px 4px 0 0, #000 4px 4px 0 1px;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-family: ITCAvantGardeStd-Bk, Arial, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin: 0px 5px 5px 10px;
    overflow: visible;
    padding: 12px 40px;
    text-align: center;
    text-transform: none;
    touch-action: manipulation;
    user-select: none;
    -webkit-user-select: none;
    vertical-align: middle;
    white-space: nowrap;
}

.slideShowButton:focus {
    text-decoration: none;
}

.slideShowButton:hover {
    background-color: #8A2124;
}

.slideShowButton:active {
    box-shadow: rgba(0, 0, 0, 0.125) 0 3px 5px inset;
    outline: 0;
}

.slideShowButton:not([disabled]):active {
    box-shadow: #fff 2px 2px 0 0, #000 2px 2px 0 1px;
    transform: translate(2px, 2px);
}

/* Media queries */
@media screen and (max-width: 768px) {
    .youtube-title {
        font-size: xx-large;
    }

    .portfolio-block {
        flex-direction: column;
        padding-bottom: 20%;
    }


    .youtube-videos-left {
        float: none;
        padding-left: 0%;
         height: 100vh/2.5;
    width: 100vw;
    }

    .youtube-videos-right {
        float: none;
        padding-right: 0%;
         height: 100vh/2.5;
    width: 100vw/1;
    }

    .videoText-left {
        font-size: large;
        padding-right: 0%;
    }

    .videoText-right {
        font-size: large;
        padding-left: 0%;
    }
}