@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@500&display=swap");

/* Footer Styles */
/* .footer {
    background-color: red;
    color: red;
    text-align: center;
    z-index: 4;
    padding: 20px 0;
} */

.footer {
    z-index: 10;
    height: 80px;
    width: 100%;
    display: flex;
    align-items: center;
    top: 0;
    position: sticky;
    background-color: transparent;
}

.footer p {
    margin: 0;
    color: white;
    padding-left: 2rem;
    font-size: 16px;
    /* Adjust the font size as needed */
}