@import url(https://fonts.googleapis.com/css?family=Saira+Stencil+One);


/* .youtube-with-title {
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 1rem 1rem;
} */

.homeTitleSection {
    justify-content: center;
    align-items: center;
    display: flex;
    height: 50vh;
    z-index: 3;
}

.homeTitle {
    font-size: 4vw;
    font-family: "Saira Stencil One", cursive;
    font-weight: 400!important;
}

/* #featured-work {
    text-align: center;
    z-index: 3;
    color: white;
}

.featured-work {
    display: flex;
    width: 90vw;
    color: white;
    z-index: 3;
    font-family: "Oswald", sans-serif;
    align-items: center;
    justify-content: space-evenly;
} */

/* Media queries */
@media screen and (max-width: 768px) {
    .homeTitle {
        font-size: 6vw;
    }

}