.contactForm {
    width: 30%;
    background-color: black;
     margin: 1rem auto;
    padding: 32px;
    border: 1px solid #ccc;
   border-radius: 10px; /* Curved border */
    box-shadow: inset 0 0 20px rgba(0, 0, 0, 1), 0 0 40px #8A2124;
     display: flex; /* Use Flexbox */
  flex-direction: column; /* Stack child elements vertically */
}

.formGroup {
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
}

.formInput {
    width: 100%;
    height: 30px;
      border: none;
  border-bottom: 1px solid #ccc; /* Underline-style border */
  background-color: transparent;
  outline: none; /* Remove default focus outline */
  color: white;
    font-size: 20px; /* Set the desired font size */
}

label {
  text-align: left;
  margin-bottom: 5px;
color: whitesmoke;
font-size: medium;
font-weight: lighter;
}

.button {
  background-color: #000;
  background-image: none;
  border: 1px solid #000;
  border-radius: 4px;
  box-shadow: #fff 4px 4px 0 0,#000 4px 4px 0 1px;
  box-sizing: border-box;
  cursor: pointer;
  color: white;
  display: inline-block;
  font-family: ITCAvantGardeStd-Bk,Arial,sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  margin: 0 5px 10px 0;
  overflow: visible;
  padding: 12px 40px;
  text-align: center;
  text-transform: none;
  touch-action: manipulation;
  user-select: none;
  -webkit-user-select: none;
  vertical-align: middle;
  white-space: nowrap;
}

.button:focus {
  text-decoration: none;
}

.button:hover {
  text-decoration: none;
}

.button:active {
  box-shadow: rgba(0, 0, 0, .125) 0 3px 5px inset;
  outline: 0;
}

.button:not([disabled]):active {
  box-shadow: #fff 2px 2px 0 0, #000 2px 2px 0 1px;
  transform: translate(2px, 2px);
}

@media (min-width: 768px) {
  .button {
    padding: 12px 50px;
  }
}
