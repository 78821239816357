/*
CONTACT PAGE
*/


.contactPage {
    z-index: 3;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    
}

.logoImages {
    height: 110%;
    width: 15%;
    color: white;
    margin: 3% 0%;
}

.logoImages:hover {
    color: #8A2124;
}


#contact-title {
    font-size: 7vw;
}

.cust-quotes {
 width: 90vw;
 font-size: large;
}

#lets-start {
    font-size: xx-large;
}
