@import url("https://fonts.googleapis.com/css2?family=Overpass:ital,wght@1,200&family=Questrial&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@500&display=swap");

/*
ANIMATIONS
*/

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}


/*
GENERAL CSS
*/

* {
  font-family: "Oswald", sans-serif;
}

.pageContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 3;
  color: white;
}
