.slideshow {
  position: relative;
  width: 90%;
  z-index: 3;
  aspect-ratio: 3 / 1;
}

.slide {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  border-radius: 16px;
  box-shadow: inset 0 0 20px rgba(0, 0, 0, 1), 0 0 50px #8A2124;
  transition: opacity 1s ease-in-out;
}

.active {
  opacity: 1;
}

/* Media queries */
@media screen and (max-width: 768px) {
  .slideshow {
    display: none;
  }
}