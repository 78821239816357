@keyframes animateSaturn {
    0% {
        background-position: 0, 0;
    }

    100% {
        background-position: -1000px, 0;
    }
}

@keyframes spin {
    0% {
        transform: perspective(1000px) rotateY(360deg) rotateX(15deg) translateY(-30px);
    }

    100% {
        transform: perspective(1000px) rotateY(0deg) rotateX(15deg) translateY(-30px);
    }
}

@-webkit-keyframes animateSaturn {
    0% {
        background-position: 0, 0;
    }

    100% {
        background-position: -1000px, 0;
    }
}

@-webkit-keyframes spin {
    0% {
        transform: perspective(1000px) rotateY(360deg) rotateX(15deg) translateY(-30px);
    }

    100% {
        transform: perspective(1000px) rotateY(0deg) rotateX(15deg) translateY(-30px);
    }
}


/*
SATURN CSS
*/

.saturn {
    display: flex;
    height: 150px;
    width: 200px;
    /* padding-left: 10%;
    padding-right: 10%; */
    justify-content: center;
    align-items: center;
    transform-style: preserve-3d;
    -webkit-backface-visibility: hidden;
    -webkit-transform-style: preserve-3d;
    backface-visibility: hidden;
    z-index: 3;
}

.planet {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100px;
    height: 100px;
    transform: translate(-50%, -50%);
    background: url(../../assets/home/saturn_korks.jpg);
    background-size: cover;
    background-repeat: repeat-x;
    border-radius: 50%;
    box-shadow: inset 0 0 20px rgba(0, 0, 0, 1), 0 0 50px #8A2124;
    animation: animateSaturn 15s linear infinite;
}

.ring {
    transform-style: preserve-3d;
    animation: spin 8s linear infinite;
}

.ring span {
    font-family: monospace;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #8A2124;
    color: white;
    font-size: 0.5em;
    transform-origin: center;
    transform-style: preserve-3d;
    border-bottom: black;
    padding: 5px 10px;
    transform: rotateY(calc(var(--i) * calc(360deg / 34))) translateZ(75px);
}

/* Media queries */
@media screen and (max-width: 768px) {
    .saturn {
        display: none;
    }
}