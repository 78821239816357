@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@500&display=swap");

/* Navbar Styles */
.navbar {
    z-index: 10;
    height: 80px;
    width: 100%;
    display: flex;
    align-items: center;
    position: sticky;
    top: 0;
    padding: 1rem 0rem;
    background-color: black;
}

#nav-spaceman-photo {
    height: 80px;
    width: 100;
}

.nav-menu {
    margin-left: auto;
}

.nav-menu ul {
    display: flex;
    justify-content: space-between;
    list-style-type: none;
}

.nav-menu li {
    margin-right: 2rem;
}

.nav-menu li:last-child {
    margin-right: 2rem;
}

.nav-menu li a {
    text-decoration: none;
    color: white;
    font-size: larger;
    overflow: hidden;
    white-space: nowrap;
}

.nav-menu a.active {
    color: #8A2124;
}

.nav-menu a:hover {
    color: #8A2124;
    transition: all 0.1s ease-in;
}

/* Hamburger button styles */
.hamburger {
    background-color: black;
    color: white;
    font-size: large;
    border: 0;
    cursor: pointer;
    display: none;
    position: absolute;
    top: 50%;
    right: 25px;
    transform: translate(0, -50%);
    transition: background-color 0.2s ease-in-out;
}

/* Expanded menu */
.nav-menu.expanded ul {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 80px);
    justify-content: center;
    position: absolute;
    top: 80px;
    left: 0;
    width: 100%;
    padding: 0;
    margin: 0;
    background-color: black;
    color: white;
    text-align: center;
}

.nav-menu.expanded li {
    margin: 1rem 0;
}

.nav-menu.expanded li a {
    font-size: 1.5rem;
    padding: 1rem;
    color: white;
}

.nav-menu.expanded li a:hover {
    color: #8A2124;
}

/* Media queries */
@media screen and (max-width: 768px) {

    /* Show hamburger */
    .hamburger {
        display: block;
    }

    /* Hide nav items */
    .nav-menu ul {
        display: flex;
        flex-direction: column;
    }

    .nav-menu ul li {
        margin-right: unset;
        margin-top: 2rem;
        margin-left: 1rem;
    }

    .nav-menu {
        position: absolute;
        right: 0;
        top: 80px;
        background-color: black;
        width: 0px;
        height: calc(100vh - 80px);
        transition: all 0.2s ease-in;
        overflow: hidden;
    }

    .nav-menu.active {
        width: 50vw;
    }
}